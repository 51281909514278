.map-container {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
  height: 120vh;
  position: relative;
  background-color: #000000;
  margin-top: 0;
}

/* Style for the SVG container */
.map-container svg {
  width: 100%;
  height: 100%;
  display: block;
  background-color: #000000;
}

/* Style for the ComposableMap component */
.rsm-svg {
  background-color: #000000;
}

/* Modal Styles */
.project-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 2000;
  overflow-y: auto;
  padding: 20px;
}

.project-modal-content {
  background-color: #ffffff;
  color: #000000;
  padding: 3rem;
  border-radius: 10px;
  max-width: 800px;
  width: 90%;
  margin: 20px auto;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow-y: auto;
  max-height: 90vh;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.project-modal-content::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.project-modal-content {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.close-button {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 32px;
  height: 32px;
  background-color: #540000;
  border: none;
  border-radius: 50%;
  color: white;
  font-size: 24px;
  line-height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  transition: background-color 0.3s ease;
}

.close-button:hover {
  background-color: #3d0000;
}

.project-image {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  border-radius: 8px;
  margin: 1rem 0;
}

.project-details {
  margin-top: 2rem;
}

.project-details h2 {
  font-size: 2.2rem;
  margin-bottom: 2rem;
  color: #000000;
  line-height: 1.2;
  word-wrap: break-word;
  white-space: normal;
}

.project-details h3 {
  font-size: 1.4rem;
  margin: 2.5rem 0 1rem;
  color: #000000;
  line-height: 1.3;
}

.project-details p {
  margin-bottom: 1.5rem;
  line-height: 1.8;
  color: #000000;
  font-size: 1.1rem;
  word-wrap: break-word;
  white-space: normal;
}

/* Ensure N/A text is black */
.project-details > p {
  color: #000000;
}

.org-links {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 1rem 0 2rem;
}

.org-links a,
.progress-link,
.share-link {
  color: #ffffff;
  text-decoration: none;
  padding: 12px 20px;
  background-color: #540000;
  border-radius: 20px;
  transition: background-color 0.3s;
  display: inline-block;
  font-size: 1rem;
}

.org-links a:hover,
.progress-link:hover,
.share-link:hover {
  background-color: #6b0000;
}

.share-link {
  margin-top: 1rem;
  display: inline-block;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .project-modal {
    padding: 10px;
  }

  .project-modal-content {
    width: 95%;
    padding: 1rem;
    margin: 10px auto;
  }

  .project-details h2 {
    font-size: 1.5rem;
  }

  .project-details h3 {
    font-size: 1.1rem;
  }

  .org-links {
    flex-direction: column;
    gap: 0.5rem;
  }

  .org-links a {
    width: 100%;
    text-align: center;
  }
}

/* Map Pin Styles */
.map-pin {
  cursor: pointer;
  filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.7));
  transition: all 0.3s ease;
}

.map-pin:hover {
  filter: drop-shadow(0 0 12px rgba(255, 255, 255, 1)) 
         drop-shadow(0 0 20px rgba(84, 0, 0, 0.8));
  transform: scale(1.4);
  transition: all 0.3s ease;
}

.project-section {
  padding: 2rem 0;
  width: 100%;
  overflow-x: hidden;
  margin-bottom: 3rem;
}

.project-section.with-border {
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  margin-top: 3rem;
  padding-top: 3rem;
}

.map-description {
  text-align: center;
  max-width: 800px;
  margin: 0 auto 10px;
  padding: 10px 20px;
  position: absolute;
  top: -200px;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
}

.map-description h2 {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #ffffff;
}

.map-description p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #ffffff;
  opacity: 0.9;
}

.contributing-link {
  color: #ffffff;
  text-decoration: none;
  padding: 8px 12px;
  background-color: #540000;
  border-radius: 20px;
  transition: background-color 0.3s;
  display: inline-block;
}

.contributing-link:hover {
  background-color: #6b0000;
}

.contributing-links {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 0.5rem 0;
}

.contributing-links a {
  background-color: #540000;
  padding: 8px 12px;
}
