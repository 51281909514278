.home-metrics-section {
  width: 100%;
  padding: 200px 20px 60px;
  background-color: #000000;
  position: relative;
  z-index: 1;
}

.metrics-title {
  text-align: center;
  color: #ffffff;
  font-family: "Bicubik", sans-serif;
  font-size: 2.5rem;
  font-weight: 900;
  margin-bottom: 60px;
}

.home-metrics-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.home-metric-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-height: 150px;
  width: 100%;
  position: relative;
}

.home-metric-number {
  font-family: 'Bicubik', sans-serif;
  font-size: 2.5rem;
  font-weight: 900;
  line-height: 1.2;
  margin-bottom: 10px;
  color: #ffffff;
}

.home-metric-label {
  font-family: "IBM Plex Mono", monospace;
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 0.05em;
  color: #ffffff;
  width: 100%;
  max-width: 200px;
  word-wrap: break-word;
  white-space: normal;
  overflow-wrap: break-word;
}

.see-more-metrics {
  display: block;
  text-align: center;
  margin-top: 30px;
  font-family: "IBM Plex Mono", monospace;
  font-size: 0.9rem;
  font-weight: 300;
  color: #ffffff;
  text-decoration: none;
  transition: opacity 0.3s ease;
}

.see-more-metrics:hover {
  opacity: 0.8;
}

@media (max-width: 768px) {
  .metrics-title {
    font-size: 2rem;
    margin-bottom: 40px;
  }

  .home-metrics-container {
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .home-metric-item {
    min-height: 120px;
  }

  .home-metric-number {
    font-size: 2rem;
  }

  .home-metric-label {
    font-size: 0.9rem;
    max-width: 250px;
  }
} 