.follow-section {
  background-color: #000000;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 40px 20px;
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 1s ease-out, transform 1.2s cubic-bezier(0.17, 0.84, 0.44, 1);
  position: relative;
  z-index: 2;
  will-change: opacity, transform;
  text-align: center;
}

.follow-section.visible {
  opacity: 1;
  transform: translateY(0);
}

.follow-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 100%;
}

.section-text {
  color: #ffffff;
  font-size: 1.2rem;
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
}

.social-media-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.instagram-button {
  display: flex;
  align-items: center;
  gap: 12px;
  background: linear-gradient(45deg, #405DE6, #5851DB, #833AB4, #C13584, #E1306C, #FD1D1D);
  color: white;
  padding: 12px 24px;
  border-radius: 30px;
  text-decoration: none;
  font-weight: 600;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.instagram-button:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 15px rgba(225, 48, 108, 0.3);
}

.instagram-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.instagram-icon svg {
  width: 24px;
  height: 24px;
}

.reels-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 20px auto;
}

.reel-container {
  position: relative;
  width: 100%;
  aspect-ratio: 1/1;
  background: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  display: block;
  text-decoration: none;
}

.reel-container:hover {
  transform: scale(1.02);
}

/* Video styles */
.reel-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.reel-container:hover .reel-video {
  opacity: 1;
}

.reel-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.3s ease;
}

.reel-thumbnail.hidden {
  opacity: 0;
}

/* Carousel styles */
.carousel-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.carousel-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.5s ease;
}

/* Show first image by default */
.carousel-image:first-child {
  opacity: 1;
}

/* On hover, show the active image */
.reel-container:hover .carousel-image {
  opacity: 0;
}

.reel-container:hover .carousel-image.active {
  opacity: 1;
}

/* Single photo styles */
.reel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease;
}

.reel-container:hover .reel-image {
  opacity: 0.9;
}

.reel-embed {
  width: 100%;
  height: 100%;
  opacity: 0.8;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reel-embed iframe {
  width: 100% !important;
  height: 100% !important;
  min-width: 280px !important;
  max-width: 400px !important;
  margin: 0 auto !important;
  border: none !important;
}

.reel-embed.hovered {
  opacity: 1;
}

.reel-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: pointer;
}

/* Add a subtle hover effect to indicate clickability */
.reel-container:hover .reel-link::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  pointer-events: none;
}

@media (max-width: 1024px) {
  .reels-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .follow-section {
    padding: 30px 15px;
  }

  .section-text {
    font-size: 1.1rem;
    padding: 0 15px;
  }

  .instagram-button {
    padding: 10px 20px;
    font-size: 0.9rem;
  }

  .reels-grid {
    grid-template-columns: 1fr;
    max-width: 400px;
  }
}

.error-message {
  color: #ff4444;
  background: rgba(255, 68, 68, 0.1);
  padding: 15px;
  border-radius: 8px;
  margin: 20px 0;
  text-align: center;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
} 