/* @import './breakpoints.css'; */

@font-face {
  font-family: 'Bicubik';
  src: url('./Bicubik.OTF') format('opentype');
}

/* Reset styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
  font-family: "IBM Plex Mono", monospace;
  background-color: #000000;
  color: #ffffff;
  line-height: 1.4;
}

/* Home page styles */
.Home {
  width: 100%;
  background-color: #000000;
  min-height: 100vh;
  position: relative;
}

/* Hero section with titles */
.hero-section {
  height: 100vh;
  width: 100%;
  position: relative;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.video-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid #ffffff;
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.hero-video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  object-fit: cover;
  z-index: 0;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.hero-video.loaded {
  opacity: 1;
}

.banner-content {
  width: 90%;
  max-width: 1200px;
  text-align: center;
  z-index: 1;
  padding: 1rem;
  position: relative;
}

.banner-title {
  color: #ffffff;
  font-size: 5.5rem;
  line-height: 1.2;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  font-family: 'Bicubik', sans-serif;
  letter-spacing: 0.05em;
}

.first-line,
.second-line {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
  width: 100%;
  white-space: nowrap;
  text-align: center;
}

.first-line.visible,
.second-line.visible {
  opacity: 1;
  transform: translateY(0);
}

.second-line {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 300;
  font-size: 3rem;
  letter-spacing: 0.1em;
  position: relative;
  display: inline-block;
  padding: 0 20px;
}

.second-line::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #ffffff;
  box-shadow: 0 0 10px #ffffff, 0 0 20px #ffffff;
  transition: width 1.2s ease-out;
  opacity: 0.8;
}

.second-line.visible::after {
  width: 100%;
}

/* About section */
.about-container {
  width: 100%;
  background-color: #000000;
  position: relative;
  z-index: 2;
  padding: 2rem 1rem;
}

/* Map section */
.map-section {
  min-height: 100vh;
  width: 100%;
  padding: 2rem 1rem;
  background-color: #000000;
}

/* Footer */
.home-footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: #000000;
}

.footer-logo {
  width: 120px;
  opacity: 0.9;
  transition: opacity 0.3s ease;
}

.footer-logo:hover {
  opacity: 1;
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
  .first-line,
  .second-line,
  .second-line::after {
    transition: none;
  }
}

/* Print styles */
@media print {
  .banner-content {
    position: static;
    transform: none;
    margin: 2rem auto;
  }

  .banner-title {
    color: #000000;
  }
}

.sub {
  font-family: "IBM Plex Mono", monospace;
}

