.mission-vision-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 20px;
  background-color: #000000;
  min-height: 100vh;
  position: relative;
  z-index: 2;
}

/* Mission Section */
.mission-section {
  width: 100%;
  margin-bottom: 60px;
}

.section-content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 60px;
}

.mission-section .text-content {
  flex: 0 1 65%;
  order: 1; /* Text on the left */
}

.mission-image {
  width: 400px;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  order: 2;
  transition: transform 1.2s cubic-bezier(0.17, 0.84, 0.44, 1);
}

/* Vision Section */
.vision-section {
  width: 100%;
  margin-top: 40px;
}

.vision-section .text-content {
  flex: 0 1 65%;
  order: 2; /* Text on the right */
}

.vision-image {
  width: 400px;
  height: auto;
  object-fit: contain;
  border-radius: 8px;
  order: 1;
  transition: transform 1.2s cubic-bezier(0.17, 0.84, 0.44, 1);
}

.section-title {
  font-size: 2.2rem;
  margin-bottom: 30px;
  color: #ffffff;
}

.section-text {
  font-size: 1.2rem;
  line-height: 1.8;
  color: #ffffff;
}

.section-text p {
  margin-bottom: 20px;
}

.mission-vision-logo {
  width: 120px;
  margin-top: 60px;
  opacity: 0.9;
}

@media (max-width: 768px) {
  .mission-vision-page-container {
    padding: 100px 20px 60px;
  }

  .section-content-wrapper {
    flex-direction: column;
    gap: 40px;
  }

  .mission-section .text-content,
  .vision-section .text-content {
    order: 1;
    text-align: center;
  }

  .mission-image,
  .vision-image {
    width: 250px;
    height: auto;
    order: 2;
  }

  .section-title {
    text-align: center;
  }

  .mission-vision-logo {
    width: 100px;
    margin-top: 40px;
  }
}

.mission-vision-page-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 120px 20px 80px;
  background-color: #000000;
  color: #ffffff;
  overflow-y: auto;
  position: relative;
}

.mission-vision-page-container .content-wrapper {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
  gap: 60px;
}

.video-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.mission-vision-page-container .section-title {
  text-align: center;
  margin-bottom: 40px;
  width: 100%;
  color: #ffffff;
}

.mission-vision-page-container .section-text {
  font-size: 1.2rem;
  line-height: 1.8;
  text-align: center;
  width: 100%;
  color: #ffffff;
}

.mission-vision-page-container .section-text p {
  margin-bottom: 20px;
  text-align: center;
  color: #ffffff;
}

.mission-vision-page-container .mission-vision-logo {
  width: 120px;
  margin-top: 60px;
  opacity: 0.9;
}

.mission-section, .vision-section {
  width: 100%;
  padding: 40px 0;
}

@media (max-width: 768px) {
  .mission-vision-page-container {
    padding: 100px 20px 60px;
  }

  .mission-vision-page-container .section-text {
    font-size: 1.1rem;
    line-height: 1.6;
  }

  .mission-vision-page-container .mission-vision-logo {
    width: 100px;
    margin-top: 40px;
  }
} 