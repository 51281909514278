.about-sub-section {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: #000000;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
  margin-top: 80px;
  padding-top: 40px;
}

.about-sub-section.visible {
  opacity: 1;
  transform: translateY(0);
}

.about-sub {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  padding: 120px 0 0;
  max-width: 850px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  background-color: #000000;
}

.about-sub p {
  font-size: 1.2rem;
  line-height: 1.5;
  margin: 20px 0;
  text-align: left;
  padding: 0 20px 0 55px;
  color: #ffffff;
  opacity: 1;
  position: relative;
}

.number-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  font-size: 0.8rem;
  font-weight: bold;
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}

.animated-underline {
  position: relative;
}

.animated-underline::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 2px;
  width: 0;
  height: 1px;
  background-color: #ffffff;
  transition: width 0.3s ease;
}

.animated-underline:hover::after {
  width: 100%;
} 