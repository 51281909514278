.banner-section {
  height: 35vh;
  position: relative;
  background-color: #000000;
  padding: 40px 0;
  overflow: hidden;
  margin-bottom: 0;
}

.banner-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.banner-section .banner-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 2;
  padding: 20px;
}

.banner-section .banner-text-container {
  width: 100%;
  text-align: center;
}

.banner-section .banner-title {
  color: #ffffff;
  font-size: 4.5rem;
  line-height: 1.6;
  margin: 0;
  padding: 10px 0;
  display: inline-block;
  text-align: center;
}

/* Only apply these styles to banner-section, not the home page animation */
.banner-section .first-line,
.banner-section .second-line {
  display: block;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
  text-align: center;
  width: 100%;
  padding: 5px 0;
}

.banner-section .first-line.visible,
.banner-section .second-line.visible {
  opacity: 1;
  transform: translateY(0);
}

.banner-section .second-line::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #ffffff;
  box-shadow: 0 0 10px #ffffff, 0 0 20px #ffffff;
  transition: width 1.2s ease-out;
  opacity: 0.8;
}

.banner-section .second-line.visible::after {
  width: 100%;
}

@media (max-width: 768px) {
  .banner-section {
    height: 20vh;
  }
  
  .banner-section .banner-title {
    font-size: 2rem;
  }
} 