/* @import 'breakpoints.css'; */

/* Reset styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;
  scroll-behavior: smooth;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.5;
  background-color: #000000;
  color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Container */
.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem;
  max-width: 1140px;
}

/* Section styles */
.section {
  padding: 2rem 0;
  background-color: #000000;
  position: relative;
}

.section.animate {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.section.animate.visible {
  opacity: 1;
  transform: translateY(0);
}

/* Typography */
.section-title {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: #ffffff;
}

.section-text {
  font-size: 1.125rem;
  line-height: 1.6;
  color: #ffffff;
  margin-bottom: 1rem;
}

/* Content wrapper */
.content-wrapper {
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
}

/* Flexbox utilities */
.flex-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

/* Grid utilities */
.grid-container {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

/* Spacing utilities */
.margin-bottom {
  margin-bottom: 1rem;
}

.padding-y {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

/* Animation classes */
.animate-section {
  opacity: 0;
  transform: translateY(50px);
  transition: all 0.8s ease-out;
}

.animate-section.visible {
  opacity: 1;
  transform: translateY(0);
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.banner-section {
  margin-bottom: 20px;
}

.product-section {
  margin-top: 200px;
}

.team-section {
  margin-top: 200px;
} 