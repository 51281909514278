@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@300;400;500&display=swap');

.metrics-section {
  width: 100%;
  padding: 120px 20px 60px;
  background-color: #000000;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.metrics-section h1 {
  text-align: center;
  font-family: "Bicubik", sans-serif;
  font-size: 2.5rem;
  font-weight: 900;
  color: #ffffff;
  margin-bottom: 60px;
}

.metrics-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  overflow: hidden;
  position: relative;
}

.metrics-wrapper {
  display: flex;
  gap: 60px;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
}

.metrics-wrapper:hover {
  animation-play-state: paused;
}

.metric-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-height: 200px;
  width: 250px;
  flex: 0 0 250px;
}

.metric-icon {
  font-size: 3.5rem;
  margin-bottom: 20px;
  color: #ffffff;
}

.metric-number {
  font-family: 'Bicubik', sans-serif;
  font-size: 2.5rem;
  font-weight: 900;
  line-height: 1.2;
  margin-bottom: 10px;
  color: #ffffff;
}

.metric-label {
  font-family: "IBM Plex Mono", monospace;
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 0.05em;
  color: #ffffff;
  width: 100%;
  max-width: 250px;
  word-wrap: break-word;
  white-space: normal;
  overflow-wrap: break-word;
}

.metric-description {
  font-family: "IBM Plex Mono", monospace;
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 0.02em;
  color: #ffffff;
  width: 100%;
  max-width: 250px;
  word-wrap: break-word;
  white-space: normal;
  overflow-wrap: break-word;
  line-height: 1.6;
}

@media (max-width: 1024px) {
  .metrics-container {
    gap: 40px;
  }
}

@media (max-width: 768px) {
  .metrics-container {
    gap: 30px;
  }

  .metric-item {
    min-height: 150px;
    width: 200px;
    flex: 0 0 200px;
  }

  .metric-number {
    font-size: 2rem;
  }

  .metric-label {
    font-size: 0.9rem;
    max-width: 300px;
  }

  .metric-description {
    font-size: 0.9rem;
    max-width: 300px;
  }
} 