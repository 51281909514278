/* @import './breakpoints.css'; */

@font-face {
  font-family: 'Bicubik';
  src: url('./Bicubik.OTF') format('opentype');
}

/* Base font size for the navbar - 16px is browser default */
:root {
  --nav-base-size: 0.875rem;  /* 14px */
  --nav-large-size: 1rem;     /* 16px */
  --nav-small-size: 0.75rem;  /* 12px */
  --nav-height: 60px;
  --nav-padding: 1rem;
  --nav-logo-size: 50px;  /* Increased from 25px to 35px */
  --nav-item-gap: 1.5rem;  
}

/* MAIN NAVBAR */
.navbar-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
  margin: 0;
  padding: 0;
  height: var(--nav-height);
  line-height: 0;
  transform: translateY(0);
}

.navbar-top {
  width: 100%;
  height: 100%;
  background-color: #000000;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  margin: 0;
  line-height: 0;
  border-bottom: none;
}

.navbar-top.scrolled {
  background-color: rgba(0, 0, 0, 0.95);
}

.navbar-brand {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: auto;
}

.navbar-logo {
  height: var(--nav-logo-size);
  width: auto;
  transition: transform 0.3s ease;
}

.navbar-logo:hover {
  transform: scale(1.05);
}

/* NAVIGATION LINKS */
.nav-links {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-item {
  position: relative;
  margin: 0 1rem;
  font-size: 0.9rem;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.nav-item span {
  font-size: 0.9rem;
}

/* Adjust spacing for specific nav items */
.nav-item:nth-child(2), /* ABOUT + */
.nav-item:nth-child(3) { /* RESTORATION + */
  margin-right: 0.5rem;
}

/* Keep larger spacing for other items */
.nav-item:not(:nth-child(2)):not(:nth-child(3)) {
  margin-right: 2rem;
}

/* Dropdown styles */
.dropdown-wrapper {
  position: absolute;
  top: calc(100% + 1rem);  /* Added 1rem gap between nav item and dropdown */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  min-width: 200px;
  border-radius: 8px;
  padding: 0.5rem;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  transform-origin: top center;
  transform: translateX(-50%) translateY(-10px);
}

.nav-item:hover .dropdown-wrapper {
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) translateY(0);
}

.dropdown-content {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;  /* Reduced gap between items */
}

.dropdown-item {
  padding: 0.8rem 1rem;
  color: #ffffff;
  text-decoration: none;
  display: block;
  font-size: 0.85rem;
  transition: background-color 0.3s;
  text-align: center;  /* Center justify the text */
  white-space: nowrap;  /* Prevent text wrapping */
  border-radius: 20px;  /* Add rounded corners */
}

.dropdown-item:hover {
  background-color: #540000;
  color: #ffffff;
}

/* Accessibility */
.skip-link {
  position: absolute;
  top: -40px;
  left: 0;
  background: #540000;
  color: #ffffff;
  padding: 0.5rem 1rem;
  z-index: 1001;
  transition: top 0.3s ease;
  font-family: "IBM Plex Mono", monospace;
}

.skip-link:focus {
  top: 0;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-top,
  .navbar-logo,
  .nav-item,
  .dropdown-wrapper,
  .nav-links,
  .skip-link {
    transition: none;
  }
}
