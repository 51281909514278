/* Existing styles for the project image and slide container */
@import url("https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");
.project-image {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  max-height: 400px;
  object-fit: contain;
  border-style: none;
  border-radius: 1%;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  width: auto;
  height: auto;
  background-color: #f5f5f5;
}

.project-page {
  min-height: 100vh;
  width: 100%;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  position: relative;
}

.map-description {
  width: 100%;
  padding: 100px 20px 40px;
  background-color: #000000;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  color: #ffffff;
  font-family: "IBM Plex Mono", monospace;
  height: 200px;
}

.map-description h2 {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #ffffff;
  font-family: "Bicubik", sans-serif;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-weight: 900;
  letter-spacing: 0.5px;
}

.map-description p {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #ffffff;
  opacity: 1;
  max-width: 800px;
  margin: 0 auto;
  font-family: "IBM Plex Mono", monospace;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  font-weight: 300;
  letter-spacing: 0.2px;
}

.map-section {
  width: 100%;
  height: 120vh;
  position: relative;
  margin-top: 200px;
  z-index: 1;
}

/* Update background color for project sections to white */
.project-page .project-section {
  background-color: #ffffff;
  /* White background */
}

.slide {
  padding: 10px;
  text-align: center;
}

/* Custom styling for the slick dots */
.slick-dots {
  display: block;
  margin-bottom: 10px;
}

/* Styles for buttons */
.volunteer-button,
.donate-button,
.share-button {
  color: rgb(0, 0, 0);
  /* Text color for better visibility on white buttons */
  background-color: rgb(255, 255, 255);
  /* White background for buttons */
  padding: 10px 20px;
  text-decoration: none;
  display: inline-block;
  font-family: Arial, sans-serif;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s, color 0.2s; /* Transition for background and text color */
}

.project-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

/* Styles for the project description and details */
.project-content {
  text-align: center;
  max-width: 80%;
  margin: 20px auto;
  line-height: 1.6;
}

.project-details {
  background-color: white; /* Slightly transparent white for legibility */
  color: rgb(0, 0, 0);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.project-details h4 {
  margin-bottom: 10px;
}

.project-details ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.project-details li {
  color: rgb(
    0,
    0,
    0
  ); /* Black text color for list items for better visibility */
}

@media (max-width: 768px) {
  .project-details ul {
    flex-direction: column;
  }
}

.collapsible-header {
  cursor: pointer;
  padding: 10px;
  background-color: #f0f0f0;
  color: black; /* Text color for collapsible header */
  margin-top: 10px;
}

.collapsible-content {
  padding: 10px;
  border-top: 1px solid #eee;
  color: #000000;
}

.collapsible-content p,
.collapsible-content div,
.collapsible-content span,
.collapsible-content strong {
  color: #000000 !important;
}

.project-details .collapsible-content ul li,
.project-details .collapsible-content ul li a {
  color: #000000;
}

.slick-prev,
.slick-next {
  top: 20%; /* Adjust this value to position the arrows higher */
  transform: translateY(
    -50%
  ); /* This keeps the arrow vertically centered relative to its height */
  z-index: 99; /* Ensures arrows are above other content */
}

.slick-prev {
  left: 200px;
} /* Adjust left positioning for the previous arrow */
.slick-next {
  right: 250px;
} /* Adjust right positioning for the next arrow */

.custom-arrow {
  background-color: rgba(
    255,
    255,
    255,
    0.8
  ); /* Light background with a bit of transparency */
  border-radius: 50%; /* Circular background */
  width: 40px; /* Width of the arrow container */
  height: 40px; /* Height of the arrow container */
  display: flex; /* Use flex to center the arrow icon inside the container */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  position: absolute; /* Position arrows absolutely within the slider */
}

/* Arrow visibility */
.slick-prev:before,
.slick-next:before {
  color: #ffffff; /* Keep arrows black */
  font-size: 30px; /* Adjust size as needed */
}

/* Adjust hover state to prevent box appearance */
.slick-prev:hover,
.slick-next:hover,
.slick-prev:focus,
.slick-next:focus {
  background: transparent; /* Ensures no background color change on hover/focus */
  outline: none; /* Removes any focus outline that might appear */
}

/* Ensure arrow color remains unchanged on hover */
.slick-prev:hover:before,
.slick-next:hover:before {
  color: #ffffff; /* Maintains the arrow color on hover */
}

.org-links {
  color: #ffffff !important;
  text-decoration: none;
  padding: 12px 20px;
  background-color: #540000;
  border-radius: 20px;
  transition: background-color 0.3s;
  display: inline-block;
  font-size: 1rem;
  font-family: "IBM Plex Mono", monospace;
  font-weight: 500;
  letter-spacing: 0.2px;
}

.org-links:hover {
  background-color: #6b0000;
  color: #ffffff !important;
}

.project-details .collapsible-content ul li a.org-links {
  color: #ffffff !important;
}

.swipe-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  /* Transparent black background */
  color: #000000;
  /* Black text color */
  padding: 20px;
  border-radius: 10px;
  z-index: 1000;
  /* Ensure overlay is above everything else */
  background-color: rgba(255, 255, 255, 0.9);
}

.swipe-overlay p {
  margin: 0;
  color: #000000;
}

.hd {
  font-family: "Archivo Black";
}
.sub {
  font-family: "Raleway";
  font-weight: 600;

  border-style: none;
  color: black;
}

.loading-message,
.error-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  text-align: center;
  font-size: 1.2rem;
  color: #333;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  margin: 2rem auto;
  max-width: 600px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.error-message {
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
}

.retry-button {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: white;
  background-color: #540000;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.retry-button:hover {
  background-color: #3d0000;
}

.projects-carousel {
  width: 100%;
  padding: 20px;
  background-color: #000000;
  margin-top: 20px;
  margin-bottom: 80px;
  position: relative;
  z-index: 1;
}

.project-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-modal-content {
  background-color: #ffffff;
  color: #000000;
  padding: 3rem;
  border-radius: 10px;
  max-width: 800px;
  width: 90%;
  margin: 20px auto;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow-y: auto;
  max-height: 90vh;
  z-index: 10000;
  font-family: "IBM Plex Mono", monospace;
}

.project-modal-content h2.hd {
  font-family: "Bicubik", sans-serif;
  font-weight: 900;
  letter-spacing: 0.5px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 1.5rem;
}

.project-modal-content .sub {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 300;
  letter-spacing: 0.2px;
}

.project-modal-content .collapsible-header {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 600;
  letter-spacing: 0.2px;
}

.project-modal-content .org-links {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 500;
  letter-spacing: 0.2px;
}

.project-modal-content .project-image {
  width: 100%;
  max-width: 800px;
  height: auto;
  border-radius: 8px;
  margin: 2rem auto;
  display: block;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.nigeria-project-image {
  max-height: 250px;
  max-width: 400px;
  object-fit: contain;
  margin: 1rem auto;
}

.turtle-project-image {
  max-height: 300px;
  max-width: 500px;
  object-fit: contain;
  margin: 1rem auto;
}

.image-credit {
  text-align: center;
  font-size: 0.7rem;
  color: #414141;
  font-style: italic;
  margin: 0.5rem auto 2rem;
  max-width: 800px;
  font-family: "IBM Plex Mono", monospace;
}

.image-credit a {
  color: #540000;
  text-decoration: none;
  transition: color 0.2s ease;
}

.image-credit a:hover {
  color: #3d0000;
  text-decoration: underline;
}

@media (max-width: 768px) {
  .project-modal-content h2.hd {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .image-credit {
    font-size: 0.7rem;
    margin: 0.5rem auto 1.5rem;
  }
}
