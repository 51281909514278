.product-section {
  width: 100%;
  max-width: 100%;
  margin: 100px auto 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000000;
}

.content-wrapper {
  width: 100%;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 60px;
  margin: 0 auto;
  box-sizing: border-box;
}

.section-title {
  text-align: center;
  width: 100%;
  font-size: 1.8rem;
  line-height: 1.4;
  margin-bottom: 2rem;
  padding: 0;
  box-sizing: border-box;
}

.intro-section {
  text-align: center;
  width: 100%;
  max-width: 100%;
  margin: 0 auto 3rem;
  padding: 0;
  box-sizing: border-box;
}

.intro-section .section-text {
  max-width: 100%;
  margin: 0 auto 2rem;
  font-size: 1rem;
  line-height: 1.6;
}

.product-intro {
  display: flex;
  gap: 4rem;
  align-items: flex-start;
  margin-bottom: 4rem;
  width: 100%;
  max-width: 100%;
  padding: 0;
  box-sizing: border-box;
}

.intro-text {
  flex: 1;
  max-width: 100%;
}

.intro-text .section-text {
  max-width: 100%;
  margin-bottom: 2rem;
  font-size: 1rem;
  line-height: 1.6;
}

.product-image-container {
  flex: 1;
  max-width: 600px;
}

.product-image-link {
  display: block;
  cursor: pointer;
}

.product-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product-image:hover {
  transform: scale(1.02);
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.2);
}

.product-details {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
}

.product-details .section-text {
  max-width: 100%;
  margin: 0 auto 2rem;
  font-size: 1rem;
  line-height: 1.6;
}

.section-text {
  text-align: left;
  margin-bottom: 1.5rem;
  line-height: 1.6;
  color: #333;
  font-size: 1rem;
  max-width: 100%;
}

.product-button {
  display: inline-block;
  color: #ffffff;
  background-color: #540000;
  border-radius: 20px;
  padding: 8px 12px;
  cursor: pointer;
  white-space: nowrap;
  font-size: 16px;
  text-decoration: none;
  transition: background-color 0.3s ease-in-out;
  margin-left: 8px;
}

.product-button:hover {
  background-color: #6a0000;
  color: #ffffff;
  text-decoration: none;
}

@media (max-width: 1024px) {
  .product-intro {
    gap: 2rem;
  }
  
  .intro-text {
    max-width: 500px;
  }
  
  .product-image-container {
    max-width: 400px;
  }
}

@media (max-width: 768px) {
  .product-intro {
    flex-direction: column;
    gap: 2rem;
  }
  
  .intro-text,
  .product-image-container {
    max-width: 100%;
  }
  
  .section-title {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }
  
  .section-text {
    font-size: 1rem;
  }
  
  .product-details {
    margin-top: 2rem;
  }
} 