.error-page {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  padding: 20px;
}

.error-content {
  text-align: center;
  max-width: 600px;
  padding: 40px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
}

.error-content h1 {
  font-size: 120px;
  margin: 0;
  color: #540000;
  font-weight: bold;
  line-height: 1;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.error-content h2 {
  font-size: 32px;
  margin: 20px 0;
  color: #ffffff;
}

.error-content p {
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 30px;
  line-height: 1.6;
}

.home-button {
  display: inline-block;
  padding: 12px 24px;
  background-color: #540000;
  color: #ffffff;
  text-decoration: none;
  border-radius: 25px;
  font-size: 16px;
  transition: all 0.3s ease;
  border: 2px solid transparent;
}

.home-button:hover {
  background-color: #3d0000;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(84, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .error-content h1 {
    font-size: 80px;
  }

  .error-content h2 {
    font-size: 24px;
  }

  .error-content p {
    font-size: 16px;
  }
} 