.partners-section {
  background-color: #000000;
  justify-content: center;
  align-items: center;
  max-width: 850px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 40px 20px;
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 1s ease-out, transform 1.2s cubic-bezier(0.17, 0.84, 0.44, 1);
  position: relative;
  z-index: 2;
  will-change: opacity, transform;
  overflow: hidden;
}

.partners-section.visible {
  opacity: 1;
  transform: translateY(0);
}

.partners-section .content-wrapper {
  width: 100%;
}

.partners-section .section-title {
  text-align: center;
  margin-bottom: 40px;
  color: #ffffff;
  font-family: "Bicubik", sans-serif;
  font-size: 2.5rem;
  font-weight: 900;
}

.partners-container {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.partners-wrapper {
  display: flex;
  gap: 40px;
  animation: scroll 12s linear infinite;
  width: max-content;
}

.partners-wrapper:hover {
  animation-play-state: paused;
}

.partner-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex: 0 0 200px;
  text-decoration: none;
}

.partner-logo {
  width: 150px;
  height: 150px;
  object-fit: contain;
  transition: transform 0.3s ease;
}

.partner-item:hover .partner-logo {
  transform: scale(1.05);
}

.partner-name {
  color: #ffffff;
  font-family: "IBM Plex Mono", monospace;
  font-size: 1rem;
  font-weight: 300;
  text-align: center;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-200px * 3 - 40px * 3));
  }
}

@media (max-width: 768px) {
  .partner-item {
    flex: 0 0 150px;
  }

  .partner-logo {
    width: 100px;
    height: 100px;
  }

  .partner-name {
    font-size: 0.9rem;
  }
} 