.team-section {
  background-color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 850px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 40px 20px;
  position: relative;
  z-index: 2;
}

.team-section .content-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.team-container {
  display: flex;
  flex-direction: column;
  gap: 60px;
  width: 100%;
}

.team-section-wrapper {
  width: 100%;
}

.team-subtitle {
  color: #ffffff;
  font-size: 2rem;
  text-align: center;
  margin-bottom: 40px;
  font-weight: 500;
}

.team-member {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.team-photo {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
  border: 4px solid #fff;
}

.team-name {
  font-size: 1.8rem;
  color: #ffffff;
  margin: 10px 0;
}

.team-position {
  font-size: 1.2rem;
  color: #ffffff;
  margin-bottom: 15px;
}

.linkedin-logo {
  width: 40px;
  height: 40px;
  margin-top: 10px;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.linkedin-logo:hover {
  transform: scale(1.1);
}

.partners-grid {
  display: flex;
  justify-content: center;
  gap: 120px;
  padding: 20px;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.partner-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  transition: transform 0.3s ease;
}

.partner-item:hover {
  transform: translateY(-5px);
}

.partner-link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.partner-logo {
  width: 150px;
  height: 150px;
  object-fit: contain;
  transition: transform 0.3s ease;
}

.partner-logo:hover {
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .team-section {
    padding: 30px 15px;
  }

  .team-subtitle {
    font-size: 1.8rem;
    margin-bottom: 30px;
  }

  .team-photo {
    width: 180px;
    height: 180px;
  }

  .team-name {
    font-size: 1.6rem;
  }

  .team-position {
    font-size: 1.1rem;
  }

  .partners-grid {
    flex-direction: column;
    gap: 40px;
    align-items: center;
  }

  .partner-logo {
    width: 120px;
    height: 120px;
  }
}

.section-title {
  text-align: center;
  width: 100%;
  margin-bottom: 40px;
  color: #ffffff;
} 