/* NavBarBottom.css */
.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    height: 180px;
    overflow: hidden;
    width: 100%;
    background-color: transparent;
    z-index: 1000;
    position: relative;
    margin-top: auto;
}

.aurora-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 130%;
    overflow: hidden;
    z-index: -1;
}

.aurora-container svg {
    width: 100%;
    height: 130%;
    position: absolute;
    bottom: 0;
}

@keyframes float-slow {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-20px); }
}

@keyframes float-medium {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-15px); }
}

.animate-float-slow {
    animation: float-slow 20s ease-in-out infinite;
}

.animate-float-medium {
    animation: float-medium 15s ease-in-out infinite;
}

.navbar .nav-links {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
}

.navbar .nav-item {
    color: white;
    text-decoration: none;
    padding: 0 15px;
    transition: color 0.3s;
    font-size: 14px;
    position: relative;
}

/* Remove the black hover color */
.navbar .nav-item:hover,
.navbar .nav-item:hover a {
    color: #ffffff !important;
}

@media (max-width: 768px) {
    .navbar {
        height: 150px;
    }

    .navbar-content {
        flex-direction: column;
        gap: 1rem;
    }

    .social-icons {
        margin-top: 1rem;
        gap: 2rem;
    }

    .social-link {
        width: 36px;
        height: 36px;
        padding: 7px;
    }

    .social-icon {
        width: 22px;
        height: 22px;
    }
}

/* Main structure styles */
body {
    margin: 0;
    padding: 0;
}

#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

/* All main content should be wrapped in this */
main {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.navbar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: 2;
}

.social-icons {
    display: flex;
    align-items: center;
    gap: 2.5rem;
    margin-left: auto;
}

.social-link {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    width: 40px;
    height: 40px;
    background-color: #000000;
    border-radius: 50%;
    padding: 8px;
    box-shadow: 0 0 0 rgba(255, 255, 255, 0);
}

.social-icon {
    width: 24px;
    height: 24px;
    transition: transform 0.3s ease;
}

.social-link:hover {
    transform: scale(1.1);
    background-color: #333333;
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.3);
}

.social-link:hover .social-icon {
    transform: scale(1.1);
}

/* UNUSED OLD CODE: */

/* .navbar-brand {
    display: flex;
    align-items: center;
    height: 100%;
}
 */
/* .navbar-logo {
    height: 50px; /* Adjust logo size as needed 
    width: auto;
    margin-right: 15px;
} */

/* Specific styling for the Game tab */
/* .game-tab { 
    display: flex;
    align-items: center;
} */

/* .game-tab-text {
    color: white; /* White text color */
    /* text-decoration: none;
    padding: 0 5px;
    transition: color 0.3s; 
} 

/* .game-tab p {
    color: rgba(0, 242, 255, 0.819); /* White text color */
    /* text-decoration: none;
    font-size: 0.75rem;
} */ 

/* .game-tab-text:hover {
    color: #61dafb; /* Light blue on hover for some visual effect 
}  */

/* Ensure all main content pages take up available space */
/* .Home, .about-page {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
} */