.legal-container {
  max-width: 1200px;
  margin: 80px auto 0;
  padding: 40px 20px;
  color: #ffffff;
}

.legal-container h1 {
  font-size: 2.5rem;
  color: #ffffff;
  margin-bottom: 40px;
  text-align: center;
}

.legal-content {
  background: #000000;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(255, 255, 255, 0.1);
}

.legal-content section {
  margin-bottom: 30px;
}

.legal-content h2 {
  font-size: 1.5rem;
  color: #ffffff;
  margin-bottom: 15px;
}

.legal-content p {
  line-height: 1.6;
  margin-bottom: 15px;
  color: #ffffff;
}

.legal-content ul {
  margin-left: 20px;
  margin-bottom: 15px;
}

.legal-content li {
  margin-bottom: 10px;
  line-height: 1.5;
  color: #ffffff;
}

@media (max-width: 768px) {
  .legal-container {
    padding: 20px 15px;
    margin-top: 60px;
  }

  .legal-container h1 {
    font-size: 2rem;
  }

  .legal-content {
    padding: 20px;
  }
} 