.indigenous-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 120px 20px 80px;
  background-color: #000000;
  color: #ffffff;
  overflow-y: auto;
  position: relative;
}

.indigenous-container .content-wrapper {
  max-width: 850px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  z-index: 2;
}

.video-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.indigenous-container .section-title {
  text-align: center;
  margin-bottom: 40px;
  width: 100%;
  color: #ffffff;
}

.indigenous-container .section-text {
  font-size: 1.2rem;
  line-height: 1.8;
  text-align: center;
  width: 100%;
  color: #ffffff;
}

.indigenous-container .section-text p {
  margin-bottom: 20px;
  text-align: center;
  color: #ffffff;
}

.indigenous-container .indigenous-logo {
  width: 120px;
  margin-top: 60px;
  opacity: 0.9;
}

@media (max-width: 768px) {
  .indigenous-container {
    padding: 100px 20px 60px;
  }

  .indigenous-container .section-text {
    font-size: 1.1rem;
    line-height: 1.6;
  }

  .indigenous-container .indigenous-logo {
    width: 100px;
    margin-top: 40px;
  }
} 