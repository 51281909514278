/* General styles */
@font-face {
  font-family: 'Bicubik';
  src: url('./Bicubik.OTF') format('opentype'); /* Use relative path */
  font-weight: normal;
  font-style: normal;
}

/* Global link animation */
a:not(.navbar-brand, .dropdown-item, .product-button, [class*="btn"]) {
  position: relative;
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:not(.navbar-brand, .dropdown-item, .product-button, [class*="btn"])::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0;
  height: 1px;
  background-color: #ffffff;
  transition: width 0.3s ease;
}

a:not(.navbar-brand, .dropdown-item, .product-button, [class*="btn"]):hover::after {
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "IBM Plex Mono", monospace;
  font-weight: 300;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
  color: #ffffff;
  min-height: 100vh;
  font-size: 0.9rem;
  line-height: 1.4;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Bicubik', sans-serif; /* Headers use Bicubik */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #000000;
  line-height: 1.4;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #000000;
  position: relative;
  margin: 0;
  padding: 0;
  line-height: 1.4;
}

main {
  flex: 1;
  width: 100%;
  position: relative;
  margin: 0;
  padding: 0;
  line-height: 1.4;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Keep the App-header styles as they are if they should apply globally */
.App-header {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #ffffff;
}

.App-link {
  color: #540000;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .fixed-logout-button {
  position: fixed;
  right: 20px;
  bottom: 20px;
  /* Additional styling for the button 
} */

/* About page-specific styles */
/* Add this within your About page's HTML with <div class="About">...</div> */
/* .About {
  margin: 20px auto;
  padding: 10px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  max-width: 1000px;
  background-color: #fff;
  color: #000000; /* Changed to black text */
  /* border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  text-align: center;
} */ 

/* If you need to override the App-header styles for the About page only, use this */
/* .about-page .App-header {
  background-color: #fff;
  color: #000000; /* Changed to black text 
} */

/* Include the rest of your About-specific styles here */

p {
  font-size: 0.9rem;
  line-height: 1.4;
  margin-bottom: 1rem;
}

/* Adjust heading sizes */
h1 { font-size: 2.5rem; }
h2 { font-size: 2rem; }
h3 { font-size: 1.5rem; }
h4 { font-size: 1.25rem; }
h5 { font-size: 1rem; }
h6 { font-size: 0.9rem; }
